<template>

    <div class="top-up-view">
        <ecommerce-hero
                title="Top up your line"
                :background="heroBackground">
        </ecommerce-hero>

        <div class="uk-container-large uk-margin-auto uk-padding-small" v-if="showBreadcrumbs">
            <ul class="uk-breadcrumb">
                <li><a href="#shop">Shop</a></li>
                <li class="uk-disabled"><a>Top Up</a></li>
            </ul>
        </div>

        <div class=" uk-position-relative top-up-container uk-container uk-margin-auto uk-padding-small uk-padding-remove-vertical uk-margin-top">

            <spinner
            v-if="loading"
            overlay="absolute"
            text="Performing your action, please wait"
            ></spinner>

            <div class="uk-flex uk-flex-center " v-if=" ! finished">
<!--
                <div class="uk-margin-large-right image-block uk-visible@m ">
                    <img class="uk-responsive-width " src="images/vfp_loading.gif" alt="" uk-img>
                </div>-->

                <div class="top-up-content uk-padding uk-width-2xlarge ">
                    <div class="steps uk-margin-large-bottom">
                        <steps-tight
                            @steps:requestChangeStep="handleStepChangeRequest"
                            :steps="processSteps"
                            :allowStepNav="false"
                            :currentStep="currentStep"
                        >

                        </steps-tight>
                    </div>

                    <div class="activation-steps-content uk-width-expand" v-if="type == 'activate'">
                        <div class="uk-card uk-card-default uk-card-body uk-width-expand uk-animation-slide-right"
                             v-show="currentStep == 0">

                            <div class="activation-step-0 " >
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Please enter the line number to top up</h3>
                                <p class="">Make sure to enter it manually and carefully to avoid mistakes.</p>
                                <div class="uk-margin-bottom">
                                    <form-input label="Number to Recharge"
                                                type="strictPhoneNumber"

                                                :autoTranslate="false"
                                                v-model="targetNumner"></form-input>
                                    <form-input label="Repeat Number"
                                                type="strictPhoneNumber"
                                                v-model="targetNumnerConfirm"
                                                :autoTranslate="false"></form-input>
                                </div>
                                <button-group class="uk-flex-between">
                                    <form-button type="secondary"
                                                 class="uk-margin-large-right"
                                    @click="$router.push({name: 'top-up-type', params: {type:'buy'}})">I WANT TO BUY RECHARGE</form-button>
                                    <form-button type="primary"
                                                 @click="requestConfirmActivateStepOne"
                                                 icon-end="chevron-right">I HAVE AN ACTIVATION CODE</form-button>
                                </button-group>
                            </div>

                        </div>

                        <div class="uk-card uk-card-default uk-card-body uk-animation-slide-right"
                             v-show="currentStep == 1">
                            <div class="activation-step-1 ">
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Please enter your activation code</h3>
                                <p class="">Please double check your input.</p>
                                <div class="uk-margin-bottom uk-flex">
                                    <div class="uk-margin-auto uk-width-expand">
                                        <form-input label="Your code, 12 digits"
                                                    type="text"
                                                    v-model="activationCode"
                                                    class="activation-code-input-wrapper uk-margin-remove"
                                                    :class="{'valid': isActivationCodeValid}"
                                                    :wrapper-margins="false"
                                                    :autoTranslate="false"></form-input>
                                        <div class="uk-flex uk-flex-center uk-margin-auto">
                                            <img class="uk-margin-auto" style="max-width: 100%;" src="images/prepaid_ilustration.png"/></div>
                                    </div>


                                </div>
                                <button-group class="uk-flex-between">
                                    <form-button type="secondary"
                                                 icon="chevron-left"
                                                 @click="currentStep = 0 ">BACK</form-button>
                                    <form-button type="primary"
                                                 @click="requestConfirmActivateStepTwo"
                                                 icon-end="chevron-right">CONTINUE</form-button>
                                </button-group>
                            </div>
                        </div>

                        <div class="uk-card uk-card-default uk-card-body uk-animation-slide-right"
                             v-show="currentStep == 2">

                            <div class="activation-step-0 " >
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Confirmation SMS</h3>
                                <p class="">You may <strong>OPTIONALLY</strong> get your confirmation sent to you on another number.</p>
                                <div class="uk-margin-bottom">
                                    <form-input label="Optional: number to receive SMS confirmation of the activation"
                                                type="strictPhoneNumber"
                                                :autoTranslate="false"
                                                v-model="confirmationTargetNumber"></form-input>

                                </div>
                                <button-group class="uk-flex-between">
                                    <form-button type="secondary"
                                                 icon="chevron-left"
                                                 @click="currentStep = 1 ">BACK</form-button>
                                    <form-button type="primary"
                                                 @click="requestConfirmActivateStepThree"
                                                 icon-end="chevron-right">CONTINUE</form-button>
                                </button-group>
                            </div>

                        </div>

                        <div class="uk-card uk-card-default uk-card-body uk-animation-slide-right"
                             v-show="currentStep == 3">

                            <div class="activation-step-3 " >
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Summary</h3>
                                <p class="">Please review the summery carefully! The action you are about to take is not reversible and not refundable!</p>
                                <div class="uk-margin-bottom">


                                    <div class="uk-panel uk-padding-small uk-background-muted">
                                        <h4 class=""><strong style="display:inline-block; width: 120px">Action:</strong>activating top up</h4>
                                        <span><strong style="display:inline-block; width: 120px">To Line:</strong>89 123 321</span><br>
                                        <span><strong style="display:inline-block;width: 120px">Amount:</strong>1 000 F</span><br>
                                        <span><strong style="display:inline-block;width: 120px">Using:</strong>Activation code card nr. 123 123 321 321</span><br>

                                    </div>
                                    <div class="uk-placeholder uk-text-center uk-margin" @click="confirmActivationSummary = true">
                                        GOOGLE RECAPTCHA PLACEHOLDER
                                    </div>
                                </div>
                                <button-group class="uk-flex-between">
                                    <form-button type="secondary"
                                                 icon="chevron-left"
                                                @click="currentStep = 2 ">BACK</form-button>
                                    <form-button type="primary"
                                                 v-if=" ! confirmActivationSummary"
                                                 :disabled="true"
                                                 :uk-tooltip="'Please complete the captcha challenge to activate. DEMO: click the captcha area once'"
                                                 @click="demoTopUpActivation"
                                                 icon="check">ACTIVATE</form-button>
                                    <form-button type="primary"
                                                 v-if="confirmActivationSummary"
                                                 @click="demoTopUpActivation"
                                                 icon="check">ACTIVATE</form-button>
                                </button-group>
                            </div>

                        </div>

                    </div>


                    <div class="activation-steps-content uk-width-expand" v-if="type == 'buy'">
                        <div class="uk-card uk-card-default uk-card-body uk-width-expand uk-animation-slide-right"
                             v-show="currentStep == 0">

                            <div class="buy-step-0 " >
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Please enter the line number to top up</h3>
                                <p class="">Make sure to enter it manually and carefully to avoid mistakes.</p>
                                <div class="uk-margin-bottom">
                                    <form-input label="Number to Recharge"
                                                type="strictPhoneNumber"
                                                :autoTranslate="false"
                                                v-model="targetNumner"></form-input>
                                    <form-input label="Repeat Number"
                                                type="strictPhoneNumber"
                                                v-model="targetNumnerConfirm"
                                                :autoTranslate="false"></form-input>
                                </div>
                                <button-group class="uk-flex-between">

                                    <form-button type="secondary"
                                                 @click="$router.push({name: 'top-up-type', params: {type:'activate'}})">I HAVE A RECHARGE</form-button>
                                    <form-button type="primary"
                                                 @click="requestConfirmBuyStepOne"
                                                 icon-end="chevron-right">CONTINUE</form-button>
                                </button-group>
                            </div>

                        </div>

                        <div class="uk-card uk-card-default uk-card-body uk-width-expand uk-animation-slide-right"
                             v-show="currentStep == 1">
                            <div class="activation-step-1 ">
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Please Select Your Amount</h3>
                                <p class="">Please select your desired amount of credit.</p>
                                <div class="top-up-amount-select  uk-margin-bottom uk-flex">
                                    <form-input
                                            type="buttonGroup"
                                            :label="false"
                                            :auto-translate="false"
                                            :list="topUpBuyOptions"
                                            v-model="amount"></form-input>

                                </div>
                                <button-group class="uk-flex-between">
                                    <form-button type="secondary"
                                                 icon="chevron-left"
                                                 @click="currentStep = 0 ">BACK</form-button>
                                    <form-button type="primary"
                                                 @click="requestConfirmBuyStepTwo"
                                                 icon-end="chevron-right">CONTINUE</form-button>
                                </button-group>
                            </div>
                        </div>

                        <div class="uk-card uk-card-default uk-card-body uk-width-expand uk-animation-slide-right"
                             v-show="currentStep == 2">

                            <div class="activation-step-0 " >
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Confirmation SMS</h3>
                                <p class="">You may <strong>OPTIONALLY</strong> also get your confirmation sent to you on another number.</p>
                                <form-input label="Optional: number to receive SMS confirmation of the activation"
                                            type="strictPhoneNumber"
                                            :autoTranslate="false"
                                            v-model="confirmationTargetNumber"></form-input>
                                </div>
                                <button-group class="uk-flex-between">
                                    <form-button type="secondary"
                                                 icon="chevron-left"
                                                 @click="currentStep = 1 ">BACK</form-button>
                                    <form-button type="primary"
                                                 @click="requestConfirmBuyStepThree"
                                                 icon-end="chevron-right">CONTINUE</form-button>
                                </button-group>
                            </div>
                        <div class="uk-card uk-card-default uk-card-body uk-width-expand uk-animation-slide-right"
                             v-show="currentStep == 3">

                            <div class="activation-step-3 " >
                                <h3 class="uk-card-title uk-margin-remove-bottom decorated-title">Summary</h3>
                                <p class="">Please review the summery carefully! The action you are about to take is not reversible and not refundable!</p>
                                <div class="uk-margin-bottom">
                                    <div class="uk-panel uk-padding-small uk-background-muted">
                                        <h4 class=""><strong style="display:inline-block; width: 120px">Action:</strong>activating top up</h4>
                                        <span><strong style="display:inline-block; width: 120px">To Line:</strong>89 123 321</span><br>
                                        <span><strong style="display:inline-block;width: 120px">Amount:</strong>1 000 F</span><br>
                                        <span><strong style="display:inline-block;width: 120px">Using:</strong>New purchase</span><br>

                                    </div>
                                    <div class="uk-margin">

                                        <div class="uk-placeholder uk-text-center" @click="confirmActivationSummary = true">
                                            GOOGLE RECAPTCHA PLACEHOLDER
                                        </div>
                                    </div>
                                    <button-group class="uk-flex-between">
                                        <form-button type="secondary"
                                                     icon="chevron-left"
                                                     @click="currentStep = 2 ">BACK</form-button>
                                        <form-button type="primary"
                                                     v-if=" ! confirmActivationSummary"
                                                     :disabled="true"
                                                     :uk-tooltip="'Please fill in the captcha. DEMO: just click the captcha!'"
                                                     @click="demoTopUpBuy"
                                                     icon="check">CONTINUE TO PAYMENT</form-button>
                                        <form-button type="primary"
                                                     v-if="confirmActivationSummary"
                                                     @click="demoTopUpBuy"
                                                     icon="check">CONTINUE TO PAYMENT</form-button>
                                    </button-group>
                                </div>

                            </div>

                        </div>
                    </div>



                </div>



            </div>

            <div class="uk-container-small uk-margin-auto uk-margin-large-bottom" v-if="finished">
                <div class="uk-card uk-card-default uk-card-body success uk-margin-auto"  >
                    <h4 class="uk-text-success uk-margin-remove">
                        <icon-text icon="check">Success!</icon-text>
                    </h4>
                    <p class="uk-margin-remove">
                        Your action is successful. <br>The top up is now activated, for your use!
                    </p>

                    <hr>
                    <h4 class="uk-margin-remove-bottom">Action Summary:</h4>
                    <div>
                        <strong>Number: </strong><span>89 123 123</span>
                    </div>
                    <div>
                        <strong>Amount: </strong><span>1 000 F</span>
                    </div>
                    <div>
                        <strong>Action ID: </strong><span>AA-1112-3321</span>
                    </div>
                    <hr>
                    <p>What are we doing next?</p>
                    <button-group>
                        <form-button type="secondary">Go to My Vodafone</form-button>
                        <form-button @click="reset(); $router.push({name: 'top-up-type', params: {type: 'buy'}})">Buy a Top Up</form-button>
                        <form-button @click="reset(); $router.push({name: 'top-up-type', params: {type: 'activate'}})">Activate a Top Up</form-button>
                    </button-group>
                </div>

            </div>


<!--
   <div class="uk-card-body success" v-if="finished" >
                        <h4 class="uk-text-success uk-margin-remove">
                            <icon-text icon="check">Success!</icon-text>
                        </h4>
                        <p class="uk-margin-remove">
                            Your action is successful. <br>The top up is now activated, for your use!
                        </p>

                    </div>-->

        </div>
        <!--
        <div class="uk-container-small uk-margin-auto uk-margin-large-bottom uk-padding-small">
            <div class="uk-card uk-card-default">
                    <div class="uk-card-header">
                        <h1 class="uk-card-title">
                            Top up your line
                        </h1>
                    </div>

                    <div class="uk-card-body success" v-if="finished" >
                        <h4 class="uk-text-success uk-margin-remove">
                            <icon-text icon="check">Success!</icon-text>
                        </h4>
                        <p class="uk-margin-remove">
                            Your action is successful. <br>The top up is now activated, for your use!
                        </p>

                    </div>
                    <div class="uk-card-body" v-if=" ! finished" >
                        <h4 class="uk-margin-remove-top">Please fill in the phone number to top up</h4>
                        <form-input type="text" label="Vodafone phone number" placeholder="89 232 928"></form-input>
                        <form-input type="text" label="Repeat the number" placeholder="89 232 928"></form-input>

                        <div class="type-container">

                            <div class="subscription-actions-content">
                                <button-group :tight="true">
                                    <form-button
                                            :fullWidth="true"
                                            :type="type == 'buy' ? 'primary' : 'secondary'"
                                            @click="type = 'buy'"
                                            icon="credit-card"
                                    style="border-radius: var(--border-radius-small) 0 0 0 ;">I buy a top-up</form-button>
                                    <form-button
                                            :fullWidth="true"
                                            :type="type == 'activate' ? 'primary' : 'secondary'"
                                            @click="type = 'activate'"
                                            icon="pencil"
                                            style="border-radius: 0 var(--border-radius-small) 0 0 ;">I activate a top-up</form-button>
                                </button-group>

                                <div class="activation-type-forms uk-padding-small">
                                    <div class="top-up-buy-type  uk-animation-fade" v-show="type == 'buy'">
                                        <div class="top-up-buy-step-1" v-if="buyStep == 0">
                                            <p>Pick your amount. A confirmation message will be send to your line, or the number you choose in the form below.</p>

                                            <div class="top-up-amount-select">
                                                <form-input
                                                        type="buttonGroup"
                                                        :label="false"
                                                        :auto-translate="false"
                                                        :list="topUpBuyOptions"
                                                        v-model="amount"></form-input>
                                            </div>


                                            <form-input type="email" label="Email address for receipt" placeholder="you@yourdomain.com"></form-input>

                                            <form-input type="text" label="Optional: number to send confirmation code to. Leave empty to send code do the line you are topping up" placeholder="89 123 123"></form-input>


                                            <form-button type="primary" :full-width="true" icon-end="chevron-right" @click="buyStep = 1">Continue</form-button>
                                        </div>

                                        <div class="top-up-buy-step-2" v-if="buyStep == 1">
                                            <p>Please insert the code you have received, below.</p>

                                            <div class="uk-flex uk-flex-middle">
                                                <form-input class="uk-margin-right" type="text" label="Your confirmation code" placeholder="1234"></form-input>
                                                <form-button type="secondary"  icon="reload" @click="resendCodeDemo()">Resend Code</form-button>
                                            </div>


                                            <form-button type="primary" :full-width="true" icon-end="chevron-right" @click="finished = true">Confirm & continue to payment</form-button>

                                        </div>
                                    </div>


                                    <div class="top-up-activate-type  uk-animation-fade" v-show="type == 'activate'">
                                        <div class="top-up-buy-step-1" v-if="activationStep == 0">
                                            <p>Please insert your activation code below.</p>

                                            <div class="uk-placeholder uk-text-center">ILLUSTRATION PLACEHOLDER</div>
                                            <form-input type="text" label="Top up Activation Code" placeholder="xxxxx-1231232222"></form-input>


                                            <form-input type="text" label="Optional: number to send confirmation code to. Leave empty to send code do the line you are topping up"></form-input>


                                            <form-button type="primary" :full-width="true" icon-end="chevron-right" @click="activationStep = 1">Continue</form-button>
                                        </div>

                                        <div class="top-up-buy-step-2" v-if="activationStep == 1">
                                            <p>Please insert the code you have received, below. Once validated, the top up will be activated</p>

                                            <div class="uk-flex uk-flex-middle">
                                                <form-input class="uk-margin-right" type="text" label="Your confirmation code" placeholder="1234"></form-input>
                                                <form-button type="secondary"  icon="reload" @click="resendCodeDemo()">Resend Code</form-button>
                                            </div>


                                            <form-button type="primary" :full-width="true" icon="check" @click="finished = true">ACTIVATE</form-button>

                                        </div>





                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
        </div>-->
    </div>


</template>

<script>
    import ecommerceHero from "@/client/components/ecommerce/Hero.vue";
    import asyncOperations from "@/client/extensions/composition/asyncOperations";

    export default {
        props: {
            productAlias: {
                type: [String, Boolean],
                default: 'something-something'
            },
            type: {
                type: String,
                default: 'buy',
            },
            showBreadcrumbs: {
                type: Boolean,
                default: true,
            }
        },
        components: {
            ecommerceHero
        },
        data: function () {
            return {
                topUpData: {
                    phoneNumber1: '',
                    phoneNumber2: '',

                    receiptEmail: '',
                    confirmationNumber: '',
                    topUpCode: '',
                },
                amount: 1000,
                buyStep: 0,
                activationStep: 0,
                finished: false,
                topUpBuyOptions: [
                    {
                        value: '200',
                        label: '200 F',
                    },
                    {
                        value: '500',
                        label: '500 F',
                    },
                    {
                        value: '1000',
                        label: '1000 F',
                    },
                    {
                        value: '2000',
                        label: '2000 F',
                    },
                    {
                        value: '5000',
                        label: '5000 F',
                    },
                ],
                activationSteps: [
                    {
                        label: 'Your Number',
                        complete: false,
                        locked: false
                    },
                    {
                        label: 'Your Activation Code',
                        complete: false,
                        locked: true
                    },
                    {
                        label: 'Confirmation',
                        complete: false,
                        locked: true
                    },
                    {
                        label: 'Finalization',
                        complete: false,
                        locked: true
                    },
                ],
                buySteps: [
                    {
                        label: 'Your Number',
                        complete: false,
                        locked: false
                    },
                    {
                        label: 'Your Amount',
                        complete: false,
                        locked: true
                    },
                    {
                        label: 'Confirmation',
                        complete: false,
                        locked: true
                    },
                    {
                        label: 'Finalization',
                        complete: false,
                        locked: true
                    },
                ],
                currentStep: 0,
                targetNumber: null,
                targetNumberConfirm: null,
                confirmationTargetNumber: null,
                confirmActivationSummary: false,
                loading: false,
                activationCode: null,
           };
        },
        computed: {
            product() {
                if (this.productAlias === 'subscription') {
                    return this.subscription;
                } else {
                    return this.phone;
                }
            },
            processSteps () {
                if (this.type === 'activate') {
                    return this.activationSteps;
                } else {
                    return this.buySteps;
                }

            },
            isActivationCodeValid() {
                console.log('run');
                if (typeof this.activationCode !== 'string') {
                    return false;
                }

                console.log('check length', this.activationCode.length == 12)

                return this.activationCode.length == 12;
            }

        },
        methods: {
            sourceToBackgroundCss (src) {
                return "background-image: url('"+src+"');"
            },
            resendCodeDemo() {
                this.$s.ui.notification('A code was resent per your request');
            },
            handleStepChangeRequest (a, b) {
                console.log('cought', a,b);
            },
            requestConfirmActivateStepOne() {
                this.currentStep = 1;
                this.activationSteps[0].complete = true;
                this.activationSteps[1].locked = false;

            },
            requestConfirmActivateStepTwo() {
                this.currentStep = 2;
                this.activationSteps[1].complete = true;
                this.activationSteps[2].locked = false;

            },
            requestConfirmActivateStepThree() {
                this.currentStep = 3;
                this.activationSteps[2].complete = true;
                this.activationSteps[3].locked = false;

            },
            demoTopUpActivation() {
                this.loading = true;

                setTimeout(() => {
                    this.finished = true;
                    this.loading = false;
                }, 1500);
            },
            requestConfirmBuyStepOne() {
                this.currentStep = 1;
                this.buySteps[0].complete = true;
                this.buySteps[1].locked = false;

            },
            requestConfirmBuyStepTwo() {
                this.currentStep = 2;
                this.buySteps[1].complete = true;
                this.buySteps[2].locked = false;

            },
            requestConfirmBuyStepThree() {
                this.currentStep = 3;
                this.buySteps[2].complete = true;
                this.buySteps[3].locked = false;

            },
            demoTopUpBuy() {
                this.$s.ui.notification('DEMO: User has left to payment gateway and has returned');
                this.loading = true;

                setTimeout(() => {
                    this.finished = true;
                    this.loading = false;
                }, 1500);
            },
            reset () {
                this.currentStep = 0;
                this.finished = false;
                this.loading = false;
                this.confirmActivationSummary = false;

                this.activationSteps[0].complete = false;
                this.activationSteps[1].complete = false;
                this.activationSteps[2].complete = false;
                this.activationSteps[3].complete = false;

                this.activationSteps[0].locked = false;
                this.activationSteps[1].locked = true;
                this.activationSteps[2].locked = true;
                this.activationSteps[3].locked = true;

                this.buySteps[0].complete = false;
                this.buySteps[1].complete = false;
                this.buySteps[2].complete = false;
                this.buySteps[3].complete = false;

                this.buySteps[0].locked = false;
                this.buySteps[1].locked = true;
                this.buySteps[2].locked = true;
                this.buySteps[3].locked = true;

                // TODO: clear data
            }
        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">
    // NOTICE: non-scoped style in the end
    .activation-type-forms {
        border: 1px solid var(--global-border);
        border-top: none;
    }

    .top-up-amount-select {

        .uk-button {
            border-left: 5px solid blue!important;
        }
    }

</style>
<style  lang="scss">


    .image-block {
        display: flex;
        align-items: flex-end;
        align-self: flex-end;
    }

    .top-up-amount-select {

        .uk-button-group {

        }

        button {
            border-left: 5px solid transparent;
        }

        button:nth-of-type(1) {
            border-left: 5px solid var(--global-topup-1);

            &:hover {
                background-color: var(--global-topup-1);
            }

        }

        button:nth-of-type(2) {
            border-left: 5px solid var(--global-topup-2);

            &:hover {
                background-color: var(--global-topup-2);
            }

        }

        button:nth-of-type(3) {
            border-left: 5px solid var(--global-topup-3);

            &:hover {
                background-color: var(--global-topup-3);
            }

        }

        button:nth-of-type(4) {
            border-left: 5px solid var(--global-topup-4);

            &:hover {
                background-color:  var(--global-topup-4);
            }


        }

        button:nth-of-type(5) {
            border-left: 5px solid var(--global-topup-5);

            &:hover {
                background-color: var(--global-topup-5);
            }

        }
    }


    .top-up-container  {

        .image-block {
            --ratio : var(570 / 680);

            img {

                height: calc(60vh * var(--ratio));
                width: 60vh;
            }
        }

        .top-up-content{
            padding-bottom: calc(var(--global-margin) * 2);
        }
    }


    .activation-code-input-wrapper {
        .uk-input {
            border-color: var(--global-primary-background);
        }

        &.valid {
            .uk-input {
                border-color: var(--global-success-background);
            }

        }
    }

</style>
